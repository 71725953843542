.markdown {
   font-size: 12px;
   font-weight: normal;
   p {
      margin-bottom: 0.5rem;
   }
   h1,
   h2,
   h3,
   h4,
   h5,
   h6 {
      font-size: 14px;
      font-weight: 600;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
   }
   strong {
      font-weight: 600;
   }

   table {
      border-collapse: collapse;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 15px;
   }

   thead {
      background-color: rgba(0, 0, 0, 0.1);
   }

   th,
   td {
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 8px;
   }

   th {
      font-weight: bold;
   }
}

.dark-mode .markdown {
   table {
      border-color: rgba(255, 255, 255, 0.2);
   }

   thead {
      background-color: rgba(255, 255, 255, 0.1);
   }

   th,
   td {
      border-color: rgba(255, 255, 255, 0.2);
   }
}
