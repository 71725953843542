/*
 * Silicon | Multipurpose Bootstrap 5 Template & UI Kit
 * Copyright 2023 Createx Studio
 * Theme styles
*/

// User variables
@import 'user-variables';

// Configuration & utilities
@import 'variables';
@import 'utilities';
@import '~highcharts/css/highcharts.css';

// Bootstrap
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/accordion';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/offcanvas';
@import '~bootstrap/scss/placeholders';
@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/utilities/api';

// Layout & components
@import 'reboot';
@import 'components';

// Dark mode
@import 'dark-mode';

// User custom styles
@import 'user';

@import 'theme-custom';
@import 'markdown';

body {
   font-family: Inter, sans-serif;
}
#root {
   display: flex;
   flex-direction: column;
   max-height: 100vh;
   min-height: 100vh;
   overlay: auto;
}
//
//body {
//  display: flex;
//  flex-direction: column;
//  min-height: 100vh;
//}
//
//header, footer {
//  flex-grow: 0;
//  flex-shrink: 0;
//  flex-basis: auto;
//}
//
//main {
//  flex-grow: 1;
//  flex-shrink: 0;
//  flex-basis: auto;
//}
