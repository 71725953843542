//
// Carousel
// Based on https://github.com/nolimits4web/swiper
// --------------------------------------------------

// Navigation (Prev / Next) buttons

.btn-prev,
.btn-next {
   --#{$prefix}carousel-nav-btn-border-radius: #{$carousel-nav-btn-border-radius};
   --#{$prefix}carousel-nav-btn-bg: #{$carousel-nav-btn-bg};
   --#{$prefix}carousel-nav-btn-color: #{$carousel-nav-btn-color};
   --#{$prefix}carousel-nav-btn-box-shadow: #{$carousel-nav-btn-box-shadow};
   --#{$prefix}carousel-nav-btn-hover-bg: #{$carousel-nav-btn-hover-bg};
   --#{$prefix}carousel-nav-btn-hover-color: #{$carousel-nav-btn-hover-color};
   --#{$prefix}carousel-nav-btn-hover-box-shadow: #{$carousel-nav-btn-hover-box-shadow};
   --#{$prefix}carousel-nav-btn-disabled-color: rgba(#{to-rgb($carousel-nav-btn-color)}, 0.5);
   --#{$prefix}carousel-nav-btn-font-size: #{$font-size-base};

   transition: $carousel-nav-btn-transition;
   border: 0;
   @include border-radius(var(--#{$prefix}carousel-nav-btn-border-radius));
   background-color: var(--#{$prefix}carousel-nav-btn-bg);
   color: var(--#{$prefix}carousel-nav-btn-color) !important;
   font-size: var(--#{$prefix}carousel-nav-btn-font-size);
   @include box-shadow(var(--#{$prefix}carousel-nav-btn-box-shadow));

   &:focus {
      background-color: var(--#{$prefix}carousel-nav-btn-bg);
      @include box-shadow(var(--#{$prefix}carousel-nav-btn-box-shadow));
   }

   @if dark-mode {
      &.bg-white {
         color: $carousel-nav-btn-color !important;
      }
   }

   &:not(.bg-transparent):hover {
      background-color: var(--#{$prefix}carousel-nav-btn-hover-bg) !important;
      color: var(--#{$prefix}carousel-nav-btn-hover-color) !important;
      @include box-shadow(var(--#{$prefix}carousel-nav-btn-hover-box-shadow) !important);
   }

   > i {
      font-size: 1.5em;
   }
   &.btn-sm > i {
      font-size: 1.25em;
   }
   &.btn-lg > i {
      font-size: 1.75em;
   }
   &.btn-xl > i {
      font-size: 2em;
   }
}

.swiper-button-disabled,
.swiper-button-disabled:disabled {
   background-color: var(--#{$prefix}carousel-nav-btn-bg);
   color: var(--#{$prefix}carousel-nav-btn-disabled-color) !important;
   @include box-shadow(var(--#{$prefix}carousel-nav-btn-box-shadow) !important);
   opacity: 1;
}
.swiper-button-disabled.shadow-none,
.swiper-button-disabled:disabled.shadow-none {
   box-shadow: none !important;
}
.pagination {
   .swiper-button-disabled,
   .swiper-button-disabled:disabled {
      background-color: transparent !important;
      box-shadow: none !important;
      pointer-events: none;
   }
}

.swiper {
   .btn-prev:not(.position-static),
   .btn-next:not(.position-static) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 5;
   }
   .btn-prev {
      left: $spacer * 1.25;
   }
   .btn-next {
      right: $spacer * 1.25;
   }
}

.swiper-nav-onhover {
   .btn-prev,
   .btn-next {
      opacity: 0 !important;
   }
   &:hover .btn-prev,
   &:hover .btn-next {
      opacity: 1 !important;
   }
}

// Pagination

// Bullets

.swiper-pagination {
   --#{$prefix}carousel-pagination-bullet-width: #{$carousel-bullet-width};
   --#{$prefix}carousel-pagination-bullet-height: #{$carousel-bullet-height};
   --#{$prefix}carousel-pagination-bullet-spacer: #{$carousel-bullet-spacer};
   --#{$prefix}carousel-pagination-bullet-border-radius: #{$carousel-bullet-border-radius};
   --#{$prefix}carousel-pagination-bullet-bg: #{$carousel-bullet-bg};
   --#{$prefix}carousel-pagination-bullet-active-width: #{$carousel-bullet-active-width};
   --#{$prefix}carousel-pagination-bullet-active-bg: #{$carousel-bullet-active-bg};
   --#{$prefix}carousel-pagination-progressbar-height: #{$carousel-progressbar-height};
   --#{$prefix}carousel-pagination-progressbar-bg: #{$carousel-progressbar-bg};
   --#{$prefix}carousel-pagination-progressbar-fill-bg: #{$carousel-progressbar-fill-bg};

   display: flex;
   justify-content: center;
   align-items: center;
   bottom: $spacer * 1.25;

   &.swiper-pagination-lock {
      display: none;
   }
}

.swiper-pagination-bullet {
   width: var(--#{$prefix}carousel-pagination-bullet-width);
   height: var(--#{$prefix}carousel-pagination-bullet-height);
   transition: $carousel-bullet-transition;
   @include border-radius(var(--#{$prefix}carousel-pagination-bullet-border-radius));
   background-color: var(--#{$prefix}carousel-pagination-bullet-bg);
   opacity: 1;
}

.swiper-pagination-bullet-active {
   background-color: var(--#{$prefix}carousel-pagination-bullet-active-bg);
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
   bottom: $spacer * 1.25;
   .swiper-pagination-bullet {
      margin: {
         right: calc(var(--#{$prefix}carousel-pagination-bullet-spacer) * 0.5);
         left: calc(var(--#{$prefix}carousel-pagination-bullet-spacer) * 0.5);
      }
   }
   .swiper-pagination-bullet-active {
      width: var(--#{$prefix}carousel-pagination-bullet-active-width);
   }
}

.swiper-vertical > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-vertical {
   flex-direction: column;
   right: $spacer * 1.25;
   .swiper-pagination-bullet {
      margin: {
         top: calc(var(--#{$prefix}carousel-pagination-bullet-spacer) * 0.5);
         bottom: calc(var(--#{$prefix}carousel-pagination-bullet-spacer) * 0.5);
      }
   }
   .swiper-pagination-bullet-active {
      height: var(--#{$prefix}carousel-pagination-bullet-active-width);
   }
}

// Progress bar

.swiper-pagination-progressbar {
   background-color: var(--#{$prefix}carousel-pagination-progressbar-bg);

   .swiper-pagination-progressbar-fill {
      background-color: var(--#{$prefix}carousel-pagination-progressbar-fill-bg);
   }
}

.swiper-horizontal > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal {
   height: var(--#{$prefix}carousel-pagination-progressbar-height);
}

.swiper-vertical > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-vertical {
   width: var(--#{$prefix}carousel-pagination-progressbar-height);
}

// Pager

.page-link.btn-icon {
   display: flex;
   flex-direction: column;
   align-items: center;
   flex-shrink: 0;
   justify-content: center;
   width: $btn-icon-sm-size;
   height: $btn-icon-sm-size;
   padding: 0;
}

// Scrollbar

.swiper-scrollbar {
   --#{$prefix}carousel-scrollbar-width: #{$carousel-scrollbar-width};
   --#{$prefix}carousel-scrollbar-bg: #{$carousel-scrollbar-bg};
   --#{$prefix}carousel-scrollbar-drag-bg: #{$carousel-scrollbar-drag-bg};

   background-color: var(--#{$prefix}carousel-scrollbar-bg);
}

.swiper-scrollbar-drag {
   background-color: var(--#{$prefix}carousel-scrollbar-drag-bg);
}

.swiper-vertical > .swiper-scrollbar {
   top: 0;
   height: 100%;
   width: var(--#{$prefix}carousel-scrollbar-width);
}

.swiper-horizontal > .swiper-scrollbar {
   left: 0;
   width: 100%;
   height: var(--#{$prefix}carousel-scrollbar-width);
}

.scrollbar-hover {
   .swiper-scrollbar {
      transition: opacity 0.2s ease-in-out;
      opacity: 0;
   }
   &:hover .swiper-scrollbar {
      opacity: 1;
   }
}

// Tabs

.swiper-tabs {
   position: relative;
   height: 100%;
}

.swiper-tab {
   display: flex;
   position: absolute;
   top: 0;
   left: 0;
   flex-direction: column;
   width: 100%;
   height: 100%;
   transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
   opacity: 0;
   visibility: hidden;

   &.active {
      position: relative;
      opacity: 1;
      visibility: visible;
   }
}

// Layer animations

.from-top,
.from-bottom,
.from-start,
.from-end,
.scale-up,
.scale-down,
.fade-in {
   transition: all 0.4s 0.4s ease-in-out;
   opacity: 0;
   backface-visibility: hidden;
}
.from-top {
   transform: translateY(-2.25rem);
}
.from-bottom {
   transform: translateY(2.25rem);
}
.from-start {
   transform: translateX(-2.25rem);
}
.from-end {
   transform: translateX(2.25rem);
}
.scale-up {
   transform: scale(0.85);
}
.scale-down {
   transform: scale(1.15);
}
.delay-1 {
   transition-delay: 0.5s;
}
.delay-2 {
   transition-delay: 0.7s;
}
.delay-3 {
   transition-delay: 0.9s;
}
.delay-4 {
   transition-delay: 1.1s;
}

.swiper-slide-active {
   .from-top,
   .from-bottom {
      transform: translateY(0);
      opacity: 1;
   }
   .from-start,
   .from-end {
      transform: translateX(0);
      opacity: 1;
   }
   .scale-up,
   .scale-down {
      transform: scale(1);
      opacity: 1;
   }
   .fade-in {
      opacity: 1;
   }
}

// Mobile app sliders

.mobile-app-slider {
   max-width: 328px;
   border-radius: 4rem;
   @include media-breakpoint-up(md) {
      max-width: 100%;
      .swiper-slide {
         transition: opacity 0.3s ease-in-out;
         opacity: 0.8;
         &.swiper-slide-active {
            opacity: 1;
         }
      }
   }
}
