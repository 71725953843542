//
// Audio Player
// --------------------------------------------------

.audio-player {
   --#{prefix}audio-player-theme: #{$primary};
   --seek-before-width: 0%;
   --volume-before-width: 100%;
   --buffered-width: 0%;

   display: flex;
   align-items: center;
   width: 100%;
}

// Play button

.ap-play-button {
   padding-top: 0.125rem;
   font: {
      family: 'boxicons';
      size: $h4-font-size;
      weight: normal;
      style: normal;
   }
   &::before {
      content: '\ebbd';
   }
   &.ap-pause::before {
      content: '\ebae';
   }
}

// Volume button

.ap-volume-button + .dropdown-menu {
   left: 50% !important;
   width: 2.125rem;
   min-width: 2.125rem;
   max-width: 2.125rem;
   height: 6.5rem;
   margin-left: -1.0625rem !important;
}

// Seek and volume sliders

.ap-seek-slider,
.ap-volume-slider {
   position: relative;
   -webkit-appearance: none;
   margin: 0;
   padding: 0;
   background: none;
   outline: none;

   &::before {
      position: absolute;
      left: 0;
      cursor: pointer;
      content: '';
   }
}

// Seek slider
.ap-seek-slider {
   width: 100%;
   height: 1.125rem;
}
.ap-seek-slider::-webkit-slider-runnable-track {
   width: 100%;
   height: 0.25rem;
   background: linear-gradient(
      to right,
      $gray-400 var(--buffered-width),
      rgba($gray-300, 0.9) var(--buffered-width)
   );
   cursor: pointer;
}
.ap-seek-slider::before {
   top: 0.4375rem;
   width: var(--seek-before-width);
   height: 0.25rem;
   background-color: var(--#{prefix}audio-player-theme);
}
.ap-seek-slider::-webkit-slider-thumb {
   position: relative;
   -webkit-appearance: none;
   box-sizing: content-box;
   height: 1rem;
   width: 1rem;
   border-radius: 50%;
   background-color: var(--#{prefix}audio-player-theme);
   cursor: pointer;
   margin: -0.375rem 0 0 0;
}
.ap-seek-slider:active::-webkit-slider-thumb {
   transform: scale(1.2);
}
.ap-seek-slider::-moz-range-track {
   width: 100%;
   height: 0.25rem;
   background: linear-gradient(
      to right,
      $gray-400 var(--buffered-width),
      rgba($gray-300, 0.9) var(--buffered-width)
   );
   cursor: pointer;
}
.ap-seek-slider::-moz-range-progress {
   background-color: var(--#{prefix}audio-player-theme);
}
.ap-seek-slider::-moz-focus-outer {
   border: 0;
}
.ap-seek-slider::-moz-range-thumb {
   box-sizing: content-box;
   border: 0;
   height: 1rem;
   width: 1rem;
   border-radius: 50%;
   background-color: var(--#{prefix}audio-player-theme);
   cursor: pointer;
}
.ap-seek-slider:active::-moz-range-thumb {
   transform: scale(1.2);
}

// Volume slider
.ap-volume-slider {
   top: 1.925rem;
   right: 1.5rem;
   width: 5rem;
   height: 0.5625rem;
   transform: rotate(-90deg);
}
.ap-volume-slider::-webkit-slider-runnable-track {
   width: 100%;
   height: 0.1875rem;
   background-color: var(--#{prefix}audio-player-theme);
   cursor: pointer;
}
.ap-volume-slider::before {
   top: 0.1875rem;
   width: var(--volume-before-width);
   height: 0.1875rem;
   background-color: $white;
}
.ap-volume-slider::-webkit-slider-thumb {
   position: relative;
   -webkit-appearance: none;
   box-sizing: content-box;
   height: 0.5625rem;
   width: 0.5625rem;
   border-radius: 50%;
   background-color: $white;
   cursor: pointer;
   margin: -0.1875rem 0 0 0;
}
.ap-volume-slider:active::-webkit-slider-thumb {
   transform: scale(1.2);
}
.ap-volume-slider::-moz-range-track {
   width: 100%;
   height: 0.1875rem;
   background-color: var(--#{prefix}audio-player-theme);
   cursor: pointer;
}
.ap-volume-slider::-moz-range-progress {
   background-color: $white;
}
.ap-volume-slider::-moz-focus-outer {
   border: 0;
}
.ap-volume-slider::-moz-range-thumb {
   box-sizing: content-box;
   border: 0;
   height: 0.5625rem;
   width: 0.5625rem;
   border-radius: 50%;
   background-color: $white;
   cursor: pointer;
}
.ap-volume-slider:active::-moz-range-thumb {
   transform: scale(1.2);
}
