//
// List group
// --------------------------------------------------

// Base class
//
// Easily usable on <ul>, <ol>, or <div>.

.list-group {
   // scss-docs-start list-group-css-vars
   --#{$prefix}list-group-color: #{$list-group-color};
   --#{$prefix}list-group-bg: #{$list-group-bg};
   --#{$prefix}list-group-border-color: #{$list-group-border-color};
   --#{$prefix}list-group-border-width: #{$list-group-border-width};
   --#{$prefix}list-group-border-radius: #{$list-group-border-radius};
   --#{$prefix}list-group-item-padding-x: #{$list-group-item-padding-x};
   --#{$prefix}list-group-item-padding-y: #{$list-group-item-padding-y};
   --#{$prefix}list-group-action-color: #{$list-group-action-color};
   --#{$prefix}list-group-action-hover-color: #{$list-group-action-hover-color};
   --#{$prefix}list-group-action-hover-bg: #{$list-group-hover-bg};
   --#{$prefix}list-group-action-active-color: #{$list-group-action-active-color};
   --#{$prefix}list-group-action-active-bg: #{$list-group-action-active-bg};
   --#{$prefix}list-group-disabled-color: #{$list-group-disabled-color};
   --#{$prefix}list-group-disabled-bg: #{$list-group-disabled-bg};
   --#{$prefix}list-group-active-color: #{$list-group-active-color};
   --#{$prefix}list-group-active-bg: #{$list-group-active-bg};
   --#{$prefix}list-group-active-border-color: #{$list-group-active-border-color};
   --#{$prefix}list-group-active-box-shadow: #{$list-group-active-box-shadow};
   // scss-docs-end list-group-css-vars

   display: flex;
   flex-direction: column;

   // No need to set list-style: none; since .list-group-item is block level
   padding-left: 0; // reset padding because ul and ol
   margin-bottom: 0;
   @include border-radius(var(--#{$prefix}list-group-border-radius));
}

.list-group-numbered {
   list-style-type: none;
   counter-reset: section;

   > .list-group-item::before {
      // Increments only this instance of the section counter
      content: counters(section, '.') '. ';
      counter-increment: section;
   }
}

// Individual list items
//
// Use on `li`s or `div`s within the `.list-group` parent.

.list-group-item {
   position: relative;
   display: block;
   padding: var(--#{$prefix}list-group-item-padding-y) var(--#{$prefix}list-group-item-padding-x);
   color: var(--#{$prefix}list-group-color);
   text-decoration: if($link-decoration == none, null, none);
   background-color: var(--#{$prefix}list-group-bg);
   border: var(--#{$prefix}list-group-border-width) solid var(--#{$prefix}list-group-border-color);

   &:first-child {
      @include border-top-radius(inherit);
   }

   &:last-child {
      @include border-bottom-radius(inherit);
   }

   &.disabled,
   &:disabled {
      color: var(--#{$prefix}list-group-disabled-color);
      pointer-events: none;
      background-color: var(--#{$prefix}list-group-disabled-bg);
   }

   // Include both here for `<a>`s and `<button>`s
   &.active {
      z-index: 2; // Place active items above their siblings for proper border styling
      color: var(--#{$prefix}list-group-active-color);
      background-color: var(--#{$prefix}list-group-active-bg);
      border-color: var(--#{$prefix}list-group-active-border-color);
      @include box-shadow(var(--#{$prefix}list-group-active-box-shadow));
   }

   & + & {
      border-top-width: 0;

      &.active {
         margin-top: calc(
            var(--#{$prefix}list-group-border-width) * -1
         ); // stylelint-disable-line function-disallowed-list
         border-top-width: var(--#{$prefix}list-group-border-width);
      }
   }
}

// Interactive list items
//
// Use anchor or button elements instead of `li`s or `div`s to create interactive
// list items. Includes an extra `.active` modifier class for selected items.

.list-group-item-action {
   width: 100%; // For `<button>`s (anchors become 100% by default though)
   transition: $list-group-action-transition;
   color: var(--#{$prefix}list-group-action-color);
   font-weight: $list-group-action-font-weight;
   text-align: inherit; // For `<button>`s (anchors inherit)

   // Hover state
   &:hover,
   &:focus {
      z-index: 1; // Place hover/focus items above their siblings for proper border styling
      color: var(--#{$prefix}list-group-action-hover-color);
      text-decoration: none;
      background-color: var(--#{$prefix}list-group-action-hover-bg);
   }

   &.active {
      color: var(--#{$prefix}list-group-action-active-color);
      background-color: var(--#{$prefix}list-group-action-active-bg);
   }
}

// Horizontal
//
// Change the layout of list group items from vertical (default) to horizontal.

@each $breakpoint in map-keys($grid-breakpoints) {
   @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      .list-group-horizontal#{$infix} {
         flex-direction: row;

         > .list-group-item {
            &:first-child {
               @include border-bottom-start-radius(var(--#{$prefix}list-group-border-radius));
               @include border-top-end-radius(0);
            }

            &:last-child {
               @include border-top-end-radius(var(--#{$prefix}list-group-border-radius));
               @include border-bottom-start-radius(0);
            }

            &.active {
               margin-top: 0;
            }

            + .list-group-item {
               border-top-width: var(--#{$prefix}list-group-border-width);
               border-left-width: 0;

               &.active {
                  margin-left: calc(
                     var(--#{$prefix}list-group-border-width) * -1
                  ); // stylelint-disable-line function-disallowed-list
                  border-left-width: var(--#{$prefix}list-group-border-width);
               }
            }
         }
      }
   }
}

// Flush list items
//
// Remove borders and border-radius to keep list group items edge-to-edge. Most
// useful within other components (e.g., cards).

.list-group-flush {
   @include border-radius(0);

   > .list-group-item {
      border-width: 0 0 var(--#{$prefix}list-group-border-width);

      &:last-child {
         border-bottom-width: 0;
      }
   }

   .list-group-item-action {
      margin-bottom: 0.125rem;
      border: 0;
      @include border-radius(var(--#{$prefix}list-group-border-radius));
   }
}

.card .list-group-flush .list-group-item-action {
   margin-bottom: 0;
   border-bottom: var(--#{$prefix}list-group-border-width) solid
      var(--#{$prefix}list-group-border-color);
   @include border-radius(0);
   &:last-child {
      border-bottom: 0;
   }
   &.active {
      border-color: var(--#{$prefix}list-group-active-border-color);
   }
}

.offcanvas-body .list-group-flush .list-group-item-action {
   margin-bottom: 0.0625rem;
   @include border-radius(0);
}
